import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { KeycloakService } from 'keycloak-angular';
import { Variables } from './Helpers/variables';
import { FileService } from './services/file/file.service';
import { GroupService } from './services/group/group.service';
import { LanguageService } from './services/language/language.service';
import { UserService } from './services/user/user.service';
import { PermissionService } from './services/permission/permission.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  loading = false;

  load = true;

  items: MenuItem[] = [];

  appicon: HTMLLinkElement;

  infoText = 'Information';
  closeText = 'Close';

  uid = 0;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private confirmationService: ConfirmationService,
    private cookieService: CookieService,
    private keycloakService: KeycloakService,
    private fileService: FileService,
    private language: LanguageService,
    private groupService: GroupService,
    private userService: UserService,
    private permService: PermissionService,
  ) {
    this.appicon = document.querySelector('#appicon') as HTMLLinkElement;
    this.router.navigateByUrl('');
    this.translate.addLangs(['English', 'French']);
    this.translate.setDefaultLang('English');

    let browserLang = this.translate.getBrowserLang();

    const pass = 'ranitessarldev2019';
    const lang = cookieService.get('goepla-portal-language');
    if (lang.trim() !== '') {
      const declang = CryptoJS.AES.decrypt(lang, pass).toString(CryptoJS.enc.Utf8);
      const lg = declang;
      browserLang = lg;
      localStorage.setItem('language', lg);
    }

    if (localStorage.getItem('language') !== null) {
      browserLang = localStorage.getItem('language') as string;
    }

    this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
    this.loading = true;
  }

  async ngOnInit(): Promise<any>  {
    if (localStorage.getItem('browser') === null || localStorage.getItem('browser') === 'false') {
      await this.showInfo('browser');
      return;
    }

    await this.setIcon();
    await this.getUser();
  }

  async setIcon(): Promise<any>  {
    this.loading = true;
    const file = await this.fileService.getFiles(1, 1, ' ', 'abm-icon', 'abm-bucket', ' ', ' ')
    .toPromise().catch((err: any) => this.handleError(err));
    if (file !== undefined && file !== null && file.length > 0) {
      const furl = await this.fileService.getFile('abm-bucket', file[0].fileId)
      .toPromise().catch((err: any) => this.handleError(err));
      if (furl !== null && furl.url !== null) {
        this.appicon.href = furl.url;
      }
    }
    this.loading = false;
  }

  async getUser(): Promise<any>  {
    this.loading = true;
    const eml = (await this.keycloakService.loadUserProfile()).email;
    const ress = await this.userService.getUserInfo(0, 0, eml as string, ' ').toPromise().catch((err: any) => this.handleError(err));

    if (ress !== undefined && ress !== null && ress.userId > 0) {
      this.uid = ress.userId;
      const v = new Variables();
      const oid = CryptoJS.AES.encrypt(JSON.stringify(ress.organisationId), v.pass).toString();
      const uid = CryptoJS.AES.encrypt(JSON.stringify(ress.userId), v.pass).toString();
      localStorage.setItem('oi', oid);
      localStorage.setItem('ui', uid);

      await this.showInfo('null');
      await this.getGroups();
    } else {
      this.loading = false;
      this.load = false;
      // this.router.navigateByUrl('/unauthorizeduser');
      await this.showInfo('user');
    }
  }

  async getGroups(): Promise<any>  {
    this.loading = true;
    const uid = this.uid;
    let mygroups = '';
    let username = '';
    let groupids = '';
    const usergroups = await this.groupService.getUserGroups(0, uid).toPromise()
                                                .catch((err: any) => this.handleError(err));

    if (usergroups != null && usergroups.length > 0) {
      for (const val of usergroups) {
        groupids += val.groupId + ':';
        const group = await this.groupService.getGroupById(val.groupId).toPromise()
                                              .catch((err: any) => this.handleError(err));
        if (group != null && group.length > 0) {
          mygroups += group[0].groupName + ':';
        }
      }
    }
    // localStorage.setItem('my-groups', mygroups);
    const v = new Variables();
    const gs = CryptoJS.AES.encrypt(mygroups, v.pass);
    localStorage.setItem('mg', gs.toString());

    const user = await this.userService.getUserInfo(uid, 0, ' ', ' ').toPromise()
    .catch((err: any) => this.handleError(err));
    if (user != null && user.firstName != null && user.lastName != null) {
      username = user.firstName + ' ' + user.lastName;
    }

    const name = CryptoJS.AES.encrypt(username, v.pass);
    localStorage.setItem('un', name.toString());
    // localStorage.setItem('user-name', username);
    // localStorage.setItem('user-group-ids', groupids);

    await this.getPermissions(groupids, user.organisationName, mygroups);

    this.loading = false;
  }

  async getPermissions(groupids: string | null, org: string, mygroups: string | string[]): Promise<any>  {
    this.loading = true;
    const permissions = [];
    // const groupids = localStorage.getItem('user-group-ids');
    if (groupids != null) {
      const gids = groupids.split(':');
      if (gids !== undefined && gids !== null && gids.length > 0) {
        for (const id of gids) {
          if (id !== '') {
            const perms = await this.permService.getUserPermissions(0, +id, ' ').toPromise()
            .catch((err: any) => this.handleError(err));
            if (perms != null && perms.length > 0) {
              for (const perm of perms) {
                // localStorage.setItem(perm.perm, perm.perm);
                permissions.push(perm.perm);
              }
            }
          }
        }
      }
    }

    if (org === 'ORG_SUPER_ADMIN' || mygroups.includes('Administrators-Full_Group')) {
      permissions.push('ABM.Administration.Full');
    }

    const v = new Variables();
    const p = CryptoJS.AES.encrypt(JSON.stringify(permissions), v.pass).toString();
    localStorage.setItem('ups', p);

    await this.buildMenu(permissions);
    this.loading = false;
  }

  async buildMenu(perm: any[]): Promise<any>  {
    this.router.navigateByUrl('contact-groups');
  }

  async showInfo(info: string): Promise<any>  {
    this.loading = true;
    if (this.keycloakService === undefined || this.keycloakService === null
      || this.keycloakService.getKeycloakInstance() === undefined
      || this.keycloakService.getKeycloakInstance() === null) {
      if (info === 'browser') {
        this.load = false;
        this.confirm('Browser doesn\'t fulfill the browser requirements:' +
        ' The Browser requirements are: Browser type = Chrome, Minimum browser version = 80.0.3987.122');
      } else if (info === 'user') {
        this.confirm('USER NOT FOUND IN USM. User can\'t continue using the application !!!!!');
      }
      this.loading = false;
      return;
    } else {
      if (localStorage.getItem('language') === null) {
        // Replace the user id with the users user id later
        const lang = await this.language.getLanguage(1).toPromise().catch((err: any) => this.handleError(err));
        if (lang.userId > 0) {
          localStorage.setItem('language', lang.lang);

          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language') as string;
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        } else {
          this.translate.addLangs(['English', 'French']);
          this.translate.setDefaultLang('English');

          let browserLang = this.translate.getBrowserLang();
          if (localStorage.getItem('language') !== null) {
            browserLang = localStorage.getItem('language') as string;
          }
          this.translate.use(browserLang.match(/English|French/) ? browserLang : 'English');
        }

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.INFO').subscribe((resp) => {
          this.infoText = resp;
        });

        // tslint:disable-next-line: deprecation
        this.translate.get('GENERAL.CLOSE').subscribe((resp) => {
          this.closeText = resp;
        });

        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            this.confirm(resp);
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            this.confirm(resp);
          });
        }
      } else {
        if (info === 'browser') {
          this.load = false;
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.BROWSERCHECK').subscribe((resp) => {
            this.confirm(resp);
          });
        } else if (info === 'user') {
          // tslint:disable-next-line: deprecation
          this.translate.get('GENERAL.USERNOTFOUND').subscribe((resp) => {
            this.confirm(resp);
          });
        }
      }
    }

    this.loading = false;
  }

  confirm(text: string): any {
    this.confirmationService.confirm({
        message: text,
        key: 'load',
        accept: () => {
          // Actual logic to perform a confirmation
          if (this.keycloakService !== undefined && this.keycloakService !== null
            && this.keycloakService.getKeycloakInstance() !== undefined
            && this.keycloakService.getKeycloakInstance() !== null) {
            this.keycloakService.logout();
          }
      },
      reject: () => {
        if (this.keycloakService !== undefined && this.keycloakService !== null
          && this.keycloakService.getKeycloakInstance() !== undefined
          && this.keycloakService.getKeycloakInstance() !== null) {
          this.keycloakService.logout();
        }
      }
    });
  }

  handleError(err: any): any {
    this.loading = false;
    console.log(err);
  }
}
